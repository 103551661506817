<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items"
  :pageCount="items.length"
  isHiddenTableCount
  :querySearch="getListStudentCard"
).mx-6
  Form(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Cadet',
  components: {
    Form: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'number', text: this.$t('number'), sortable: false },
        { value: 'date_start', text: this.$t('dataEnrollment'), sortable: false },
        { value: 'faculty.name_ukr', text: this.$t('faculty'), sortable: false },
        { value: 'name_nz.name_ukr', text: this.$t('nameInstitution'), sortable: false },
        { value: 'status_document', text: this.$t('status'), sortable: false },
        { value: 'event', text: this.$t('actions'), class: 'mw-0 position-relative', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: ({ sailor, id }) => this.$router.push({ name: 'education-student-info', params: { ...this.$route.params, documentID: id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.student,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getStudentCard']),
    async getListStudentCard (params) {
      await this.getStudentCard({ ...this.$route.params, params })
    }
  }
}
</script>
